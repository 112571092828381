import React, { useState, useEffect } from "react"
import * as S from "./styled"
import gsap from "gsap"

import DetailsModal from "../DetailsModal"

//* Icons
import { ExpandArrowsAlt } from "@styled-icons/fa-solid/ExpandArrowsAlt"
import { Bed } from "@styled-icons/fa-solid/Bed"
import { Car } from "@styled-icons/fa-solid/Car"

const Card = ({
  image,
  price,
  address,
  area,
  dorms,
  garage,
  suites,
  usefulArea,
  landArea,
  buildingArea,
  fotos,
  link,
  id,
}) => {
  const [show, setShow] = useState(false)

  /* useEffect(() => {
    const tl = gsap.timeline()
    if (show) {
      //* Entrar na tela
      tl.from(".details-modal-container", { opacity: 0 }).to(
        ".details-modal",
        {
          opacity: 1,
          y: 0,
        },
        "<"
      )
    } else {
      //* Sair da tela
      tl.from(".details-modal-container", { opacity: 0 }).to(
        ".details-modal",
        {
          opacity: 1,
          y: 0,
        },
        "<"
      )
    }
  }, [show]) */

  return (
    <>
      <S.Container onClick={() => setShow(true)} className="card-container">
        <S.Image image={image} />
        <h2>R$ {price}</h2>
        <p>{address}</p>
        <div>
          <span>
            <ExpandArrowsAlt size="20" color="#000" />
            {` AT ${area} m²`}
          </span>
          <span>
            <Bed size="20" color="#000" />
            {dorms}
          </span>
          <span>
            <Car size="20" color="#000" />
            {garage}
          </span>
        </div>
      </S.Container>
      {show && (
        <DetailsModal
          stateFunction={setShow}
          price={price}
          dormitory={dorms}
          suites={suites}
          garage={garage}
          totalArea={area}
          usefulArea={usefulArea}
          landArea={landArea}
          buildingArea={buildingArea}
          fotos={fotos}
          link={link}
          id={id}
        />
      )}
    </>
  )
}

export default Card
