import styled from "styled-components"
import media from "styled-media-query"

export const ModalContainer = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-y: scroll;

  ${media.lessThan("large")`
    align-items: flex-start;
  `}
`

export const Modal = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  /* opacity: 0; */
  /* transform: translateY(500px); */

  background: #fff;

  ${media.lessThan("large")`
    margin: 10vh 0;
    flex-direction: column;
  `}
  ${media.lessThan("small")`
    margin: 5vh 0 0 0;
    max-width: 100%;
  `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;

  height: 40px;
  width: 40px;

  background: var(--main-color);
  border: none;

  cursor: pointer;

  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`

export const ImagesSlider = styled.div`
  width: 100%;
  max-width: 700px;
`

export const ImageWrapper = styled.div`
  max-height: 90vh;
  height: 550px;
  background: url("${props => props.imageUrl}");
  background-size: cover;
  background-position: center;
  overflow: hidden;

  ${media.lessThan("large")`
  height: 300px;
  `}
`

export const Details = styled.div`
  margin-left: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan("large")`
  margin-left: 0;
  `}
`

export const TextWrapper = styled.div`
  h2 {
    font-size: 3em;
    font-weight: 700;
    color: var(--main-color);
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 10px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  button {
    padding: 20px;
    background: var(--main-color);
    border: none;
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;

    color: #fff;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    //transition: all 200ms ease-in-out;

    span {
      margin-left: 10px;
    }
  }

  button:hover {
    transform: scale(1.02);
  }
`
