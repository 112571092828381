import React from "react"
import * as S from "./styled"
import { ArrowIosDownwardOutline } from "@styled-icons/evaicons-outline/ArrowIosDownwardOutline"

const ShowMore = ({ loadMoreFunction }) => {
  return (
    <S.Container>
      <S.ShowMore onClick={() => loadMoreFunction()}>
        <div>
          <p>Mostrar mais</p>
          <ArrowIosDownwardOutline size="40" color="#fff" />
        </div>
      </S.ShowMore>
    </S.Container>
  )
}

export default ShowMore
