import styled from "styled-components"
import media from "styled-media-query"

export const OpenButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 5vw;

  background: #fff;
  color: var(--main-color);
  border: none;
  padding: 15px 20px;

  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);

  ${media.greaterThan("large")`
    display: none;
  `}
`
export const Modal = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;

  background: #fff;
  color: var(--main-color);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);

  padding: 5vw;

  span {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
`
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  select {
    background-color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 1.1em;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    color: var(--main-color);
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  select:hover {
    transform: scale(1.02);
  }
`
export const CloseButton = styled.button`
  position: absolute;
  top: -40px;
  right: 20px;

  height: 40px;
  width: 40px;

  background: var(--main-color);
  border: none;

  cursor: pointer;

  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`
