export const getProperties = async options => {
  /* let url = "http://localhost/regis/procurar/script.php" */
  //let url = "https://demo.qrvdesign.com/procurar/script.php"
  let url = "https://alugarimoveisemperuibe.com.br/procurar/script.php"
  const qtd = 16
  const Properties = await (
    await fetch(
      `${url}?qtd=${qtd}&position=${options.position}&tipo=${options.tipo}&valor=${options.valor}&dormitorios=${options.dormitorios}`
    )
  ).json()
  return Properties
}
