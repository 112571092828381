import styled from "styled-components"
import media from "styled-media-query"

export const LargeSearchContainer = styled.div`
  width: 90%;
  max-width: 1200px;

  display: flex;
  justify-content: center;

  color: var(--main-color);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 10px;

  margin: 5vh auto;
  padding: 30px 0;

  select {
    background-color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 1.1em;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    color: var(--main-color);
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }

  select:hover {
    transform: scale(1.02);
  }

  ${media.lessThan("large")`
    display: none;
  `}
`
