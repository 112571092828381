import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;

  padding-top: 10vh;

  background: url("${props => props.background}");
  background-size: cover;
  background-attachment: fixed;
`
