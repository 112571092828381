import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.section`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  display: grid;
  gap: 30px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${media.lessThan("large")`
  margin-top: 5vh;
  `}

  ${media.lessThan("medium")`
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  `}

  ${media.lessThan("small")`
  gap: 10px;
  `}
`
