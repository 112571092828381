import styled, { keyframes } from "styled-components"
import media from "styled-media-query"

const show = keyframes`
  from{
    transform: translateY(500px);
  }to {
    transform: translateY(0);
  }
   
`

export const Container = styled.button`
  /* animation: ${show} 1s ease-in-out; */

  /* opacity: 0;
  transform: translateY(500px); */
  background: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-height: 300px;
  transition: all 200ms ease-in-out;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &:hover {
    transform: scale(1.02);
  }

  h2 {
    font-size: 2em;
    font-weight: 700;
    margin-top: 5px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  div {
    display: flex;
  }

  span {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  ${media.lessThan("small")`
  min-height: 100px;

  img {
    width: 100%;
  }

  h2 {
    font-size: 1.2em;
  }

  p{
    font-size: 0.8em;
  }

  div {
    flex-direction: column;
  }
  `}
`
export const Image = styled.div`
  width: 100%;
  height: 200px;

  border-radius: 5px;

  background: url("${props => props.image}");
  background-size: cover;
  background-position: center;

  ${media.lessThan("small")`
  height: 100px;
  `}
`
