import React, { useState, useEffect } from "react"
import * as S from "./styled"
import gsap from "gsap"

import ItemsCarousel from "react-items-carousel"

//* Icons
import { ShareBox } from "@styled-icons/remix-line/ShareBox"
import { Whatsapp } from "@styled-icons/remix-line/Whatsapp"
import { Share } from "@styled-icons/remix-line/Share"

import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline"
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline"

import { ExpandArrowsAlt } from "@styled-icons/fa-solid/ExpandArrowsAlt"
import { Bed } from "@styled-icons/fa-solid/Bed"
import { Car } from "@styled-icons/fa-solid/Car"
import { Bath } from "@styled-icons/fa-solid/Bath"

import { Close } from "@styled-icons/evaicons-solid/Close"
import { Images } from "@styled-icons/bootstrap"

const Chevron = ({ direction }) => {
  const Icon =
    direction === "left" ? ArrowIosBackOutline : ArrowIosForwardOutline
  return <Icon size="40" color="#fff" />
}

const DetailsModal = ({
  stateFunction,
  price,
  dormitory,
  suites,
  garage,
  totalArea,
  usefulArea,
  landArea,
  buildingArea,
  fotos,
  link,
  id,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const chevronWidth = 40

  //Animation
  /* useEffect(() => {
    const tl = gsap.timeline()
    tl.from(".details-modal", { opacity: 0, y: 500 })
  }, []) */

  const share = url => {
    if (navigator.share !== undefined) {
      navigator
        .share({
          title: "Paulinho Imóveis",
          text: "Confira esse imóvel disponível em Paulinho Imóveis",
          url: url,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log(error))
    } else {
      let input = document.createElement("input")
      input.value = `Confira esse imóvel disponível em Paulinho Imóveis: ${url}`

      input.style.position = "absolute"
      input.style.left = "-9999px"

      document.querySelector("body").appendChild(input)

      //seleciona todo o texto do elemento
      input.select()
      //executa o comando copy
      //aqui é feito o ato de copiar para a area de trabalho com base na seleção
      document.execCommand("copy")

      document.querySelector(".share-button").innerHTML =
        "Link copiado para a área de transferência"
    }
  }

  const whatsappContact = link => {
    const text = `Olá!\nGostaria de saber mais sobre esse imóvel:\n${link}`
    const number = "5513997883549"
    window.location.href = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURI(
      text
    )}`
  }

  const multipleImages = () => {
    console.log(fotos.URL)
    if(fotos.URL == undefined)
      return true;
    return false;
  }

  return (
    <S.ModalContainer className="details-modal-container">
      <S.Modal className="details-modal">
        <S.ImagesSlider>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={20}
            leftChevron={<Chevron direction="left" />}
            rightChevron={<Chevron direction="right" />}
            outsideChevron={false}
            chevronWidth={chevronWidth}
          >

            {multipleImages()?fotos?.map((foto, key) => <S.ImageWrapper imageUrl={foto.URL} key={key} /> )
            :<S.ImageWrapper imageUrl={fotos.URL} />
            }

            
          </ItemsCarousel>
        </S.ImagesSlider>
        <S.Details>
          <S.CloseButton onClick={() => stateFunction(false)}>
            <Close size="30" color="#fff" />
          </S.CloseButton>
          <S.TextWrapper>
            <h2>R$ {price}</h2>
            <ul>
              <li>
                <Bed size="15" />
                <span>{dormitory} dormitórios</span>
              </li>
              <li>
                <Bath size="15" />
                <span>sendo {suites} suites</span>
              </li>
              <li>
                <Car size="15" />
                <span>{garage} vagas</span>
              </li>
              <li>
                <ExpandArrowsAlt size="15" />
                <span>{totalArea} m² total</span>
              </li>
              <li>
                <ExpandArrowsAlt size="15" />
                <span>{usefulArea} m² útil</span>
              </li>
              <li>
                <ExpandArrowsAlt size="15" />
                <span>{landArea} m² terreno</span>
              </li>
              <li>
                <ExpandArrowsAlt size="15" />
                <span>{buildingArea} m² construída</span>
              </li>
            </ul>
          </S.TextWrapper>
          <S.ButtonsWrapper>
            <button onClick={() => share(link)} className="share-button">
              <Share size="30" />
              <span>Compartilhar imóvel</span>
            </button>
            <button onClick={() => whatsappContact(link)}>
              <Whatsapp size="30" />
              <span>Falar com um expecialista agora</span>
            </button>
            <button onClick={() => (window.location.href = link)}>
              <ShareBox size="30" />
              <span>Ver mais detalhes</span>
            </button>
          </S.ButtonsWrapper>
        </S.Details>
      </S.Modal>
    </S.ModalContainer>
  )
}

export default DetailsModal
