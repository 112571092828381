import React, { useState } from "react"
import * as S from "./styled"

import { Close } from "@styled-icons/evaicons-solid/Close"

const SmallSearch = ({ setTipo, setValor, setDormitorios }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <S.OpenButton onClick={() => setShow(true)}>Filtros</S.OpenButton>
      {show && (
        <S.Modal>
          <S.CloseButton onClick={() => setShow(false)}>
            <Close size="30" color="#fff" />
          </S.CloseButton>
          <form>
            <S.ItemContainer>
              <span>Estou procurando:</span>
              <select
                name="tipo"
                id="tipo"
                onChange={event => setTipo(event.target.value)}
              >
                <option value="CASA">casas</option>
                <option value="APARTAMENTO">apartamentos</option>
                <option value="all">casas e apartamentos</option>
              </select>
            </S.ItemContainer>
            <S.ItemContainer>
              <span> Com um valor máximo de:</span>
              <select
                name="valor"
                id="valor"
                onChange={event => setValor(event.target.value)}
              >
                <option value="0">Qualquer valor</option>
                <option value="900">R$ 900,00</option>
                <option value="1500">R$ 1.500,00</option>
                <option value="2000">R$ 2.000,00</option>
                <option value="6000">R$ 6.000,00</option>
              </select>
            </S.ItemContainer>
            <S.ItemContainer>
              <span>E com dormitórios para:</span>
              <select
                name="qtdPessoas"
                id="pessoas"
                onChange={event => setDormitorios(event.target.value)}
              >
                <option value="0">qualquer quantidade</option>
                <option value="2">até 2</option>
                <option value="3">até 3</option>
                <option value="4">até 4</option>
                <option value="4">mais que 4</option>
              </select>
            </S.ItemContainer>
          </form>
        </S.Modal>
      )}
    </>
  )
}

export default SmallSearch
