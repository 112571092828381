import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
`

export const ShowMore = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 30px 0;

  transition: all 200ms ease-in-out;

  &:hover {
    background: rgba(27, 109, 236, 0.5);
  }

  p {
    color: #fff;
    font-size: 2.5em;
  }
`
