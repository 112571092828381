import React from "react"

import * as S from "./styled"

const LargeSearch = ({ setTipo, setValor, setDormitorios }) => {
  return (
    <S.LargeSearchContainer>
      <form>
        <span>Estou procurando </span>
        <select
          name="tipo"
          id="tipo"
          onChange={event => setTipo(event.target.value)}
        >
          <option value="all">casas e apartamentos</option>
          <option value="CASA">casas</option>
          <option value="APARTAMENTO">apartamentos</option>
        </select>

        <span> com um valor máximo de </span>
        <select
          name="valor"
          id="valor"
          onChange={event => setValor(event.target.value)}
        >
          <option value="0">Qualquer valor</option>
          <option value="900">R$ 900,00</option>
          <option value="1500">R$ 1.500,00</option>
          <option value="2000">R$ 2.000,00</option>
          <option value="6000">R$ 6.000,00</option>
        </select>
        <span> e com dormitórios para </span>
        <select
          name="qtdPessoas"
          id="pessoas"
          onChange={event => setDormitorios(event.target.value)}
        >
          <option value="0">qualquer quantidade de</option>
          <option value="2">até 2</option>
          <option value="3">até 3</option>
          <option value="4">até 4</option>
          <option value="4">mais que 4</option>
        </select>
        <span> pessoas</span>
      </form>
    </S.LargeSearchContainer>
  )
}

export default LargeSearch
