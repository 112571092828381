import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
`
export const Content = styled.div`
  width: 90%;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;

  color: #fff;
  font-weight: 700;
  font-size: 2em;

  display: flex;
  flex-direction: colum;
  justify-content: center;
  align-items: center;
`
