import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import ContainerWithBackground from "../components/ContainerWithBackground"

import LargeSearch from "../components/LargeSearch"
import SmallSearch from "../components/SmallSearch"

import CardsContainer from "../components/CardsContainer"
import Card from "../components/Card"
import Loading from "../components/Loading"
import ShowMore from "../components/ShowMore"
import EndOfLine from "../components/EndOfLine"

import gsap from "gsap"

//* API
import { getProperties } from "../components/API"

const PesquisaPage = () => {
  const [requireNumber, setRequireNumber] = useState(0)

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [position, setPosition] = useState(0)

  const [tipo, setTipo] = useState("all")
  const [valor, setValor] = useState(0)
  const [dormitorios, setDormitorios] = useState(0)

  const [endOfLine, setEndOfLine] = useState(false)

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    //var c = url.searchParams.get("id")
    //console.log(c)
  }, [])

  const handleClick = () => {
    console.log("Load more")
    loadProperties()
  }

  const loadProperties = async () => {
    const filterOptions = {
      tipo,
      valor,
      dormitorios,
      position,
    }
    //console.log(filterOptions)

    setLoading(true)
    const newProperties = await getProperties(filterOptions)
    //console.log(newProperties)
    if (newProperties.length === 1) {
      setEndOfLine(true)
    }
    setPosition(newProperties.pop().cursor)
    setItems(prev => [...prev, ...newProperties])
    setLoading(false)
    setRequireNumber(requireNumber + 1)
    /* const tl = gsap.timeline();
    tl.to(".card-container", { stagger: 0.1, delay: 0.1, opacity:1, y:0, ease: "power1.out"}) */
  }

  useEffect(() => {
    if (items.length === 0 && requireNumber > 0) {
      loadProperties()
    }
  }, [items])

  useEffect(() => {
    setPosition(0)
    setItems([])
    setEndOfLine(false)
  }, [tipo, valor, dormitorios])

  useEffect(() => {
    loadProperties()
  }, [])

  const multipleImages = fotos => {
    //console.log(fotos)
    if (fotos.URL == undefined) return fotos[0].URL
    else return fotos.URL
  }

  return (
    <Layout>
      <SEO title="Pesquisa" />
      <ContainerWithBackground>
        <LargeSearch
          setTipo={setTipo}
          setValor={setValor}
          setDormitorios={setDormitorios}
        />
        <SmallSearch
          setTipo={setTipo}
          setValor={setValor}
          setDormitorios={setDormitorios}
        />
        <CardsContainer>
          {items.map((item, key) => {
            return (
              <Card
                key={key}
                image={multipleImages(item.Fotos.Foto)}
                price={item.Valorlocacao}
                address={`${item.Cidade}, ${item.Bairro}`}
                area={item.Areaterrenosemdeciamal}
                dorms={item.Dormitorios}
                garage={item.Garagem}
                //* Modal
                suites={item.Suite}
                usefulArea={item.Areautilsemdeciamal}
                landArea={item.Areaterrenosemdeciamal}
                buildingArea={item.Areaconstruidasemdeciamal}
                fotos={item.Fotos.Foto}
                link={item.LinkImovelSite}
                id={item.Codigoimovel}
              />
            )
          })}
        </CardsContainer>
        {loading && <Loading />}
        {endOfLine && <EndOfLine />}
        {!endOfLine && <ShowMore loadMoreFunction={handleClick} />}
      </ContainerWithBackground>
    </Layout>
  )
}

export default PesquisaPage
