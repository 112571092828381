import React from "react"
import * as S from "./styled"

import searchImage from "../../images/searching.svg"

const Loading = () => {
  return (
    <S.Container>
      <S.ImageWrapper>
        <S.MagnifyingGlass>
          <svg
            width="250"
            height="256"
            viewBox="0 0 250 256"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.547 83.3018C30.6574 83.3018 30.6446 95.5582 38.547 95.5582C46.4493 95.5582 46.4429 83.3018 38.547 83.3018Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M93.5219 44.0322C72.2951 43.5515 52.9718 54.2053 42.7045 70.3143C39.3525 75.5579 48.87 80.1925 52.2027 74.9745C60.7075 61.6604 76.8712 53.1797 94.3359 53.6476C112.653 54.1091 129.323 64.5899 138.148 79.1348C141.5 84.654 150.268 79.6348 146.941 74.1476C136.232 56.4745 115.454 44.5258 93.5219 44.0322Z"
              fill="url(#paint1_linear)"
            />
            <path
              d="M168.866 31.1029C159.802 21.6717 148.944 14.1494 136.929 8.97869C124.915 3.80803 111.988 1.09353 98.9094 0.994971C85.8306 0.896414 72.8642 3.41579 60.7735 8.40481C48.6828 13.3938 37.7122 20.7516 28.5074 30.0452C-8.60746 67.4683 -9.23555 128.398 27.0654 166.584C43.0107 183.381 64.3851 193.991 87.4027 196.536C110.42 199.081 133.596 193.396 152.824 180.488L223.708 254.205C224.142 254.658 224.661 255.021 225.235 255.272C225.809 255.524 226.428 255.66 227.055 255.673C227.681 255.685 228.305 255.574 228.888 255.345C229.472 255.117 230.005 254.775 230.457 254.34L248.12 237.353C248.573 236.919 248.935 236.4 249.187 235.825C249.439 235.251 249.575 234.633 249.587 234.006C249.6 233.379 249.488 232.756 249.26 232.172C249.031 231.588 248.69 231.055 248.255 230.603L177.371 156.885C190.96 138.263 197.556 115.448 196.001 92.4461C194.445 69.4441 184.838 47.7252 168.866 31.1029ZM146.787 149.641C136.79 159.259 124.166 165.697 110.511 168.139C96.8567 170.581 82.7847 168.919 70.0747 163.362C57.3647 157.805 46.5877 148.603 39.1063 136.921C31.6249 125.238 27.7753 111.598 28.0442 97.7271C28.3131 83.8559 32.6884 70.3759 40.6169 58.9919C48.5455 47.6078 59.6711 38.8309 72.5869 33.771C85.5026 28.7112 99.6285 27.5956 113.178 30.5654C126.728 33.5352 139.093 40.457 148.709 50.4554C155.095 57.0943 160.11 64.9261 163.469 73.5036C166.828 82.0811 168.465 91.2364 168.287 100.447C168.108 109.657 166.117 118.742 162.428 127.183C158.739 135.624 153.424 143.255 146.787 149.641Z"
              fill="#015CE9"
            />
            <path
              d="M46.2384 79.5508C38.3488 79.5508 38.336 91.8072 46.2384 91.8072C54.1407 91.8072 54.1343 79.5508 46.2384 79.5508Z"
              fill="#015CE9"
            />
            <path
              d="M101.213 40.3018C79.9865 39.821 60.6632 50.4748 50.3959 66.5838C47.0439 71.8274 56.5614 76.462 59.8941 71.2441C68.3989 57.93 84.5626 49.4492 102.027 49.9171C120.344 50.3787 137.014 60.8595 145.84 75.4043C149.192 80.9236 157.959 75.9043 154.633 70.4171C143.923 52.7569 123.145 40.8018 101.213 40.3018Z"
              fill="#015CE9"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="38.547"
                y1="95.5646"
                x2="38.547"
                y2="83.3018"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-opacity="0.12" />
                <stop offset="0.55" stop-opacity="0.09" />
                <stop offset="1" stop-opacity="0.02" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="79950"
                y1="14112.9"
                x2="79950"
                y2="11919.2"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-opacity="0.12" />
                <stop offset="0.55" stop-opacity="0.09" />
                <stop offset="1" stop-opacity="0.02" />
              </linearGradient>
            </defs>
          </svg>
        </S.MagnifyingGlass>
        <img src={searchImage} alt="carregando..." />
      </S.ImageWrapper>
      <span>Carregando...</span>
    </S.Container>
  )
}

export default Loading
