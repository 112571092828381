import styled, { keyframes } from "styled-components"

const searchAnimation = keyframes`
  from {
    transform: translate(0,-75%);
  }25% {
    transform: translate(-150%, -100%);
  }50% {
    transform: translate(-50%, 0%);
  }75% {
    transform: translate(-150%, 10%);
  }to {
    transform: translate(0,-75%);
  }
`

export const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    color: #fff;
    font-weight: bold;
    margin-top: 30px;
  }
`

export const ImageWrapper = styled.div`
  width: 400px;
  max-width: 60%;
`
export const MagnifyingGlass = styled.div`
  max-width: 10%;
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(50%, 50%);
  svg {
    width: 100%;
    height: 100%;
  }

  animation: ${searchAnimation} 5s infinite;
`
